<template>
  <span class="emergency-title">Contact d'urgence</span>
  <p>Voulez vous que je sois le relais de la situation ?</p>
  <div @change="this.$emit('emergencyInformation', {emergencyRelay: emergencyRelay, harassment:harassment, mentalHealth:mentalHealth, discrimination:discrimination, other:other, emergencyComment:emergencyComment})">
    <input type="radio" id="yes" :value="true" v-model="emergencyRelay" />
    <label class="radio-label" for="one">Oui</label>
    <input type="radio" id="no" :value="false" v-model="emergencyRelay" />
    <label class="radio-label" for="no">Non</label>
    <div v-if="emergencyRelay" class="emergency-contact">
      <p class="bold">Soupçon de:</p>
      <label>
        <input type="checkbox" v-model="harassment" name="checkbox" />
        Harcèlement : {{ company.harassment_contact }}
      </label>
      <label>
        <input type="checkbox" v-model="mentalHealth" name="checkbox" />
        Santé mentale : {{ company.mental_health_contact }}
      </label>
      <label>
        <input type="checkbox" v-model="discrimination" name="checkbox" />
        Discrimination : {{ company.discrimination_contact }}
      </label>
      <label>
        <input type="checkbox" v-model="other" name="checkbox" />
        Autre : {{ company.other_contact }}
      </label>
      <div class="comment">
        <p>Commentaire : </p>
        <textarea rows="10" cols="50" type="text" v-model="emergencyComment" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "EmergencyContact",
  emits: ['emergencyInformation'],
  props: {
    company: Object,
    meeting: Object
  },
  data() {
    return {
      emergencyComment: this.meeting.emergency_comment,
      emergencyRelay: this.meeting.relay_emergency,
      harassment: this.meeting.relay_harassment,
      mentalHealth: this.meeting.relay_mental_health,
      discrimination: this.meeting.relay_discrimination,
      other: this.meeting.relay_other
    };
  }
}
</script>

<style>
  .emergency-contact {
    width: clamp(320px, 75vw, 575px);
    font-size: 14px;
    font-weight: 350;
  }

  .emergency-title {
    color: #FF6861;
    text-align: center;
    font-weight: 400;
    font-size: 22px;
    text-decoration: underline;
    margin-bottom: 30px;
  }

  .radio-label {
    display: inline;
    margin: 5px 10px 0 0;
  }

  label {
    display: block;
    margin-top: 5px;
  }

  input {
    margin-right: 5px;
  }

  .bold {
    font-weight: bold;
  }

  .comment {
    margin-top: 10px;
  }
</style>
