<template>
  <div class="wysiwyg-editor">
    <div v-if="editor" class="buttons">
      <div>
        <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.890625 0.546875H5.46875C6.375 0.546875 7.04948 0.585938 7.49219 0.664062C7.9401 0.736979 8.33854 0.893229 8.6875 1.13281C9.04167 1.3724 9.33594 1.69271 9.57031 2.09375C9.80469 2.48958 9.92188 2.9349 9.92188 3.42969C9.92188 3.96615 9.77604 4.45833 9.48438 4.90625C9.19792 5.35417 8.80729 5.6901 8.3125 5.91406C9.01042 6.11719 9.54688 6.46354 9.92188 6.95312C10.2969 7.44271 10.4844 8.01823 10.4844 8.67969C10.4844 9.20052 10.362 9.70833 10.1172 10.2031C9.8776 10.6927 9.54688 11.0859 9.125 11.3828C8.70833 11.6745 8.19271 11.8542 7.57812 11.9219C7.19271 11.9635 6.26302 11.9896 4.78906 12H0.890625V0.546875ZM3.20312 2.45312V5.10156H4.71875C5.61979 5.10156 6.17969 5.08854 6.39844 5.0625C6.79427 5.01562 7.10417 4.88021 7.32812 4.65625C7.55729 4.42708 7.67188 4.1276 7.67188 3.75781C7.67188 3.40365 7.57292 3.11719 7.375 2.89844C7.18229 2.67448 6.89323 2.53906 6.50781 2.49219C6.27865 2.46615 5.61979 2.45312 4.53125 2.45312H3.20312ZM3.20312 7.00781V10.0703H5.34375C6.17708 10.0703 6.70573 10.0469 6.92969 10C7.27344 9.9375 7.55208 9.78646 7.76562 9.54688C7.98438 9.30208 8.09375 8.97656 8.09375 8.57031C8.09375 8.22656 8.01042 7.9349 7.84375 7.69531C7.67708 7.45573 7.4349 7.28125 7.11719 7.17188C6.80469 7.0625 6.1224 7.00781 5.07031 7.00781H3.20312Z" fill="white"/>
          </svg>
        </button>
        <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.86719 0.15625H6.71094L6.39062 1.50781H5.28906L3.19531 10.6562H4.28125L3.97656 12H0.132812L0.4375 10.6562H1.5625L3.67188 1.50781H2.55469L2.86719 0.15625Z" fill="white"/>
          </svg>
        </button>
        <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5175 0.375999H9.3655V6.472C9.3655 8.824 8.1815 10.088 6.0055 10.088C3.8455 10.088 2.6615 8.824 2.6615 6.472V0.375999H1.4775V6.536C1.4775 9.56 3.0455 11.16 5.9895 11.16C8.9335 11.16 10.5175 9.56 10.5175 6.536V0.375999Z" fill="white"/>
            <path d="M0.4375 12.632H11.5575V13.336H0.4375V12.632Z" fill="white"/>
          </svg>
        </button>
        <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
          <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.05481 0.216C2.68681 0.216 1.13481 1.448 1.13481 3.336C1.13481 5.096 2.06281 5.912 4.87881 6.2C7.15081 6.44 7.85481 6.952 7.85481 8.088C7.85481 9.336 6.81481 10.088 5.05481 10.088C3.40681 10.088 2.04681 9.608 1.10281 8.696H1.03881V10.008C1.91881 10.76 3.32681 11.16 5.05481 11.16C7.53481 11.16 9.03881 9.992 9.03881 8.072C9.03881 6.184 8.04681 5.304 5.15081 5.016C2.97481 4.792 2.31881 4.328 2.31881 3.288C2.31881 2.088 3.42281 1.288 5.03881 1.288C6.49481 1.288 7.72681 1.72 8.43081 2.488H8.49481V1.16C7.80681 0.568 6.51081 0.216 5.05481 0.216Z" fill="white"/>
            <path d="M0.382812 6.52H9.61481V7.224H0.382812V6.52Z" fill="white"/>
          </svg>
        </button>
      </div>
    </div>
    <editor-content :editor="editor" class="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'

export default {
  components: {
    EditorContent,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.modelValue, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [
        StarterKit,
        Underline
      ],
      onUpdate: () => {
        this.$emit('update:modelValue', this.editor.getHTML())
      },
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>