<template>
  <vue-picker class="meeting-subject" v-model="selectedSubject">
    <vue-picker-option :value="subject" v-for="(subject, index) in subjects" :key="index">
      {{ subject }}
    </vue-picker-option>
  </vue-picker>
  <div v-if="hasOtherSubject" class="custom-subject">
      <label for="meeting-subject">Rédigez-le</label>
      <input id="meeting-subject" type="text" required v-model="customSubject" @change="updateParentSubject" />
  </div>
</template>

<script>
import { VuePicker, VuePickerOption } from '@invisiburu/vue-picker'

export default {
  name: "MeetingSubject",
  emits: ['update:modelValue'],
  components: {
    VuePicker, VuePickerOption
  },
  props: {
    subjects: Array,
    modelValue: String
  },
  data() {
    return {
      selectedSubject: '',
      customSubject: '',
      otherLabel: 'Autre'
    }
  },
  updated() {
    if(!this.selectedSubject) {
      if(this.subjects.includes(this.modelValue)) {
        this.selectedSubject = this.modelValue
      } else {
        this.selectedSubject = this.otherLabel
        this.customSubject = this.modelValue
      }
    }
  },
  computed: {
    hasOtherSubject() {
      return this.selectedSubject === this.otherLabel
    }
  },
  watch: {
    selectedSubject() {
      this.updateParentSubject()
    },
  },
  methods: {
    updateParentSubject() {
      if(this.hasOtherSubject) {
        this.$emit('update:modelValue', this.customSubject ? this.customSubject : this.selectedSubject)
      } else {
        this.$emit('update:modelValue', this.selectedSubject)
      }
    }
  }
}
</script>

<style scoped>

</style>
