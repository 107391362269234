<template>
  <h3 class="step-title"><span>{{number}}</span>{{title}}</h3>
</template>

<script>
export default {
  name: "MeetingStepTitle",
  props: {
    number: Number,
    title: String
  }
}
</script>

<style scoped>

</style>