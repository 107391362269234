import {default as apiAxiosInstance} from "@/services/axios/api.axios";
import apiHelper from "@/helpers/api";

class BoostPackService {
  getCurrentBoostPack() {
    return apiAxiosInstance.get(apiHelper.currentBoostPackEndpoint);
  }
  assignCoachCurrent(id) {
    return apiAxiosInstance.put(apiHelper.changeCoachEndpoint, {coach_id: id});
  }
  createBoostPackCriterionRate(boostPackId, criterions) {
    return apiAxiosInstance.post(apiHelper.createBoostPackCriterionRateEndpoint.replace('{id}', boostPackId), criterions);
  }
  getMeetingsByBoostPack(boostPackId, limit = false) {
    let params = {};
    if (limit) {
      params.limit = limit;
    }
    return apiAxiosInstance.get(
      apiHelper.getOldMeetingsByBoostPack.replace('{id}', boostPackId),
      {
        params
      });
  }
}

export default new BoostPackService();
