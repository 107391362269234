<template>
  <title-coaching title="Détail du RDV" />
  <MeetingDetail :meeting="meeting" />
  <div v-if="!isBoostPack" class="meeting-step bg-grey" v-show="displayWellbeing">
    <MeetingStepTitle :number="1" title="Baromètre bien-être au travail" />
    <Wellbeing v-if="meeting" :editable="false" :display-wellbeing="true" :anonymous_identifier="meeting.anonymous_user.identifier" />
  </div>
  <div v-if="isBoostPack" class="meeting-step bg-grey">
    <MeetingStepTitle :number="1" title="Auto-évaluation de départ sur l’objectif" />
    <Criterion :displayNB="false" :editable="false" :criterias="meeting.boost_pack ? formatBoostPackCriterion(meeting.boost_pack.criteria_rate_boost_pack, true) : ''"/>
  </div>
  <div v-if="!isBoostPack" class="meeting-step bg-grey">
    <MeetingStepTitle :number="displayWellbeing ? 2 : 1" title="Sujet du rendez-vous" />
    <MeetingSubject  :subjects="subjects" v-model="selectedSubject" />
  </div>
  <div v-else-if="isBoostPack" class="meeting-step bg-grey">
    <MeetingStepTitle :number="2" title="Objectif du parcours" />
    <p>Objectif</p>
    <p class="boostpack-obj">{{ meeting.boost_pack.description }}</p>
  </div>
  <div class="meeting-step bg-grey">
    <MeetingStepTitle :number="displayWellbeing || isBoostPack ? 3 : 2" title="Prise de note" />
    <TipTap style="background: white" v-model="noteHtml" />
  </div>
  <div v-if="displayCoachCriterion" class="meeting-step bg-grey">
    <MeetingStepTitle :number="1" title="Auto-évaluation de fin (à remplir par le coach)" />
    <Criterion :displayNB="false" :editable="criteriaCoachEditable" :criterias="meeting.boost_pack.criteria"/>
  </div>
  <div v-if="!isBoostPack" class="meeting-step bg-grey">
    <MeetingStepTitle :number="4" title="Auto-évaluation de fin (à remplir par le coach)" />
    <Criterion :key="componentKey" :displayNB="false" :editable="true" :criterias="meetingCriterias"/>
  </div>
  <div v-if="isBoostPack" class="meeting-step bg-grey readonly">
    <EmergencyContact @emergency-information="emergencyInformation"  :meeting="meeting" :company="meeting.boost_pack.user.company"/>
  </div>
  <div v-if="isBoostPack" class="meeting-step bg-grey">
    <h2>Contexte de l'entreprise</h2>
    <p class="boostpack-obj">{{ meeting.boost_pack.user.company.company_context }}</p>
  </div>
  <div class="meeting-step">
    <button class="btn-pink" @click="updateMeeting">Enregistrer</button>
  </div>
</template>

<script>
import Wellbeing from "@/components/Wellbeing/Wellbeing";
import EmergencyContact from "../../components/BoostPack/EmergencyContact";
import Criterion from "@/components/BoostPack/Criterion";
import Toastify from "toastify-js";
import TipTap from "@/components/TipTap";
import MeetingStepTitle from "@/components/Meeting/MeetingStepTitle";
import MeetingDetail from "@/components/Meeting/MeetingDetail";
import MeetingSubject from "@/components/Meeting/MeetingSubject";
import meetingsService from "@/services/meetings.service";
import TitleCoaching from "@/components/Coaching/TitleCoaching";
import BoostPackService from "../../services/boostPack.service";
import AnonymousUsersService from "../../services/anonymousUsers.service";

export default {
  name: "MeetingView",
  components: {
    MeetingSubject,
    MeetingDetail,
    MeetingStepTitle,
    TipTap,
    Wellbeing,
    Criterion,
    TitleCoaching,
    EmergencyContact
  },
  data() {
    return {
      componentKey: 0,
      displayWellbeing: false,
      meeting: null,
      noteHtml: '',
      selectedSubject: null,
      subjects: [],
      criteriaCoachEditable: true,
      meetingCriterias: null,
      emergencyComment: '',
      emergencyRelay : false,
      harassment: false,
      mentalHealth: false,
      discrimination: false,
      other: false
    }
  },
  created() {
    AnonymousUsersService.getCriteria(false, false, true).then((response) => {
      this.meetingCriterias = response.data.data.criteria.filter(x => x.type !== 'anon_stress_level_before')
    });
    meetingsService.getSubjects()
        .then((response) => {
          response.data.data.meeting_subjects.forEach(obj => this.subjects.push(obj.label));
          this.subjects.push('Autre')

          meetingsService.getMeeting(this.$route.params.id)
              .then((response) => {
                this.meeting = response.data.data.meeting;
                if (this.isBoostPack) {
                  this.meeting.boost_pack.criteria.forEach((item, key) => {
                    let index = this.meeting.boost_pack.criteria_rate_boost_pack.findIndex(x => x.criterion.type === item.type && x.is_self_assessment === false)

                    if (index >= 0) {
                      if (this.criteriaCoachEditable) {
                        this.criteriaCoachEditable = false
                      }
                      this.meeting.boost_pack.criteria[key].score = this.meeting.boost_pack.criteria_rate_boost_pack[index].score
                    }
                  })
                } else {
                  this.meetingCriterias.forEach((item, key) => {
                    let index = this.meeting.criteria_rate_meeting.findIndex(x => x.criterion.type === item.type && x.is_self_assessment === false)
                    if (index >= 0) {
                      if (this.criteriaCoachEditable) {
                        this.criteriaCoachEditable = false
                      }
                      this.meetingCriterias[key].score = this.meeting.criteria_rate_meeting[index].score
                    }
                  });
                  this.forceRerender();
                }

                this.noteHtml = this.meeting.summary;
                if (!this.meeting.boost_pack) {
                  this.selectedSubject = this.meeting.free_form_subject ?? (this.meeting.meeting_subject && this.meeting.meeting_subject.label ? this.meeting.meeting_subject.label : this.subjects[0]);
                } else {
                  this.selectedSubject = 'BoostPack Meeting subject';
                }
              });
        });
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    emergencyInformation({emergencyRelay, harassment, mentalHealth, discrimination, other, emergencyComment}) {
      this.emergencyRelay = emergencyRelay;
      this.harassment = harassment;
      this.mentalHealth = mentalHealth;
      this.discrimination = discrimination;
      this.other = other;
      this.emergencyComment = emergencyComment;
    },
    updateDisplayWellbeing(val) {
      this.displayWellbeing = val;
    },
    updateMeeting() {
      if (!this.noteHtml) {
        this.displayErrorToastify('Prise de note ne doit pas être vide');
        return;
      }
      meetingsService.updateMeeting(
          this.$route.params.id,
          this.selectedSubject,
          this.noteHtml,
          this.emergencyComment,
          this.emergencyRelay,
          this.harassment,
          this.mentalHealth,
          this.discrimination,
          this.other
      ).then(() => {
          if (this.isBoostPack && this.meeting.boost_pack.criteria && this.displayCoachCriterion) {
            let criteriasResults = this.meeting.boost_pack.criteria.filter(x => x.score !== null);
            BoostPackService.createBoostPackCriterionRate(this.meeting.boost_pack.id, criteriasResults).then(() => {
              this.displaySuccessToastify()
            });
          } else {
            if (!this.isBoostPack) {
              let criteriasResults = this.meetingCriterias.filter(x => x.score !== null);
              meetingsService.batchCriteriasMeeting(this.meeting.id, criteriasResults).then(() => {});
            }
            this.displaySuccessToastify()
          }
        },
        (error) => {
          if (error.response.status) {
            this.displayErrorToastify('Un problème est survenu lors de l\'enregistrement du rendez-vous')
          }
        });
    },
    displaySuccessToastify() {
      Toastify({
        text: 'Vos changements sont sauvegardés.',
        escapeMarkup: false,
        className: "success",
      }).showToast();
    },
    displayErrorToastify(text) {
      Toastify({
        text: text,
        escapeMarkup: false,
        className: "error",
      }).showToast();
    },
    formatBoostPackCriterion(criterion, is_self_assessment) {
      let formatedCriterion = [];
      criterion.forEach(function (item) {
        if (item.is_self_assessment === is_self_assessment) {

          item.name = item.criterion.name ?? ''
          item.type = item.criterion.type

          formatedCriterion.push(item);
        }
      });

      return formatedCriterion;
    }
  },
  computed: {
    isBoostPack() {
      return this.meeting && this.meeting.boost_pack
    },
    displayCoachCriterion() {
      if(!this.isBoostPack) {
        return false;
      }
      if (this.meeting.boost_pack.tripartite_at_end) {
        return (this.meeting.boost_pack.boost_quantity - this.meeting.boost_pack.boost_consumption) <= 1
      } else {
        return (this.meeting.boost_pack.boost_quantity - this.meeting.boost_pack.boost_consumption) === 0
      }
    }
  }
}
</script>
