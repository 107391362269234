<template>
  <div v-if="displayWellbeing">
    <Slider class="wellbeing-slider" :editable="this.editable" v-model="criteria.score"
            v-for="criteria in this.criterias" :name="criteria.name" :id="criteria.type" :key="criteria.type"/>
    <div class="text-center">
      <button v-if="this.editable" class="btn-pink" @click="updateWellBeing">Enregistrer</button>
    </div>
  </div>
</template>

<script>
import Slider from "@/components/Wellbeing/Slider";
import Toastify from "toastify-js";
import AnonymousUsersService from "@/services/anonymousUsers.service";

export default {
    name: "Wellbeing",
    components: {Slider},
    props: {
        displayWellbeing: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        anonymous_identifier: String
    },
    data() {
        return {
            criterias: []
        }
    },
    methods: {
        updateWellBeing() {
            if (this.editable) {
                let wellBeingResult = this.criterias.filter(x => x.score !== null);
                AnonymousUsersService.batchWellBeing(wellBeingResult).then(() => {
                    this.$parent.updateDisplayWellbeing(false)
                    Toastify({
                        text: 'Baromètre bien-être sauvegardé',
                        escapeMarkup: false,
                        className: "success",
                    }).showToast();
                });
            }
        }
    },
    created() {
        AnonymousUsersService.getCriteria(true, false).then((response) => {
            let criterias = response.data.data.criteria

            if (this.anonymous_identifier) {
                AnonymousUsersService.getCriteriaOpenBoostRate(this.anonymous_identifier).then((response) => {
                    let rates = response.data.data.criterion_rate_open_boost

                    rates.forEach((rate) => {
                        let index = criterias.findIndex(x => x.type === rate.criterion.type)
                        criterias[index].score = rate.score
                    });

                    this.criterias = criterias

                    if (!this.editable || this.editable && criterias.length && !rates.length) {
                        this.$parent.updateDisplayWellbeing(true)
                    }
                });
            }
        })
    }
}
</script>
