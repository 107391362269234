<template>
  <div :class="{disabled: sliderDisabled}">
    <h3 v-if="!displayBoostPackInfos">{{ name }}</h3>
    <p class="question-label" v-if="displayBoostPackInfos">Comment évaluez-vous {{ name }} :</p>
    <input :disabled="!editable" type="range" :min="minValue" :max="maxValue" v-model="sliderValue" @input="$emit('update:modelValue', parseInt($event.target.value))"  class="slider" >
    <div class="rate">
      <span class="min-slider">{{ minValue }}</span>
      <span class="value-slider" v-show="!sliderDisabled">{{ modelValue }}/{{ maxValue }}</span>
      <span class="max-slider">{{ maxValue }}</span>
    </div>
    <div v-if="editable && displaySkip" class="text-center">
      <a href="#" @click.prevent="toggleSlider">Ne se prononce pas</a>
    </div>
  </div>
</template>

<script>

export default {
  name: "Slider",
  emits: ['update:modelValue'],
  props: {
    type: String,
    name: String,
    editable: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Number,
    },
    displaySkip: {
      type: Boolean,
      default: true
    },
    displayBoostPackInfos: {
      type: Boolean,
      default: false
    },
    displayNB: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      'sliderDisabled': false,
      'minValue': 0,
      'maxValue': 10,
      'sliderValue': null
    }
  },
  methods: {
    toggleSlider() {
      this.sliderDisabled = !this.sliderDisabled;
      this.$emit('update:modelValue', this.sliderDisabled ? null : parseInt(this.sliderValue))
    }
  },
  created() {
    this.sliderValue = this.modelValue

    if(!this.sliderValue) {
      if(this.editable) {
        this.sliderValue = 5;
      } else {
        this.sliderDisabled = true;
      }
    }

    this.$emit('update:modelValue', this.sliderValue)
  }
}
</script>
