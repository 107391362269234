<template>
    <Slider v-show="criterias" :displayNB="displayNB" :displayBoostPackInfos="true" :displaySkip="false" class="wellbeing-slider" :editable="editable" v-model="criteria.score"
            v-for="criteria in criterias" :name="criteria.name" :id="criteria.type" :key="criteria.type"/>
</template>

<script>
import Slider from "../../components/Wellbeing/Slider";

export default {
  name: "BoostPackCriterion",
  props: {
    criterias: {
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    displayNB: {
      type: Boolean,
      default: false
    },
  },
  components: {Slider},
}
</script>

<style scoped>

</style>
